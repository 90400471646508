<template>
  <div class="department">
    <div class="mb-20">
      <a-button type="primary" @click="operate('add')">
        <template #icon>
          <plus-outlined />
        </template>
        新增一级分类
      </a-button>
    </div>

    <a-row class="departmentTitle">
      <a-col :span="20" style="padding-left: 35px;">课程分类名称</a-col>
      <a-col :span="4" style="text-align: center;">操作</a-col>
    </a-row>

    <a-tree
      :tree-data="treeData"
      :defaultExpandAll="false"
      :selectedKeys="selectedKeys"
      blockNode
      class="department-tree"
      :replaceFields="replaceFields"
    >
      <template v-slot:title="nodeData">
        <a-row style="border-bottom: 1px solid #eee;}">
          <a-col :span="20">{{ nodeData.title }}</a-col>
          <!-- <a-col :span="10">{{ nodeData.key }}</a-col> -->
          <a-col :span="4" style="text-align: center;">
            <a-button
              v-if="nodeData.id.length !== 6"
              type="link"
              size="small"
              @click="operate('add', nodeData)"
            >新增子级</a-button>
            <a-button type="link" size="small" @click="operate('edit', nodeData)">修改</a-button>
            <a-popconfirm
              title="确认删除该分类？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="operate('delete', nodeData)"
            >
              <a-button type="link" size="small">删除</a-button>
            </a-popconfirm>
          </a-col>
        </a-row>
      </template>
    </a-tree>

    <modal
      :title="(modalState ? '新增' : '编辑') + '分类树'"
      :width="780"
      ref="customDialog"
      @handleOk="sumbitEditForm"
    >
      <!-- <a-input v-model:value="value" placeholder="请输入课程" class="department-input" /> -->
      <a-form
        name="custom-validation"
        ref="formRef"
        :model="formState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :rules="formStateRules"
      >
        <a-form-item label="课程分类名称" name="title">
          <a-input
            v-model:value="formState.title"
            autocomplete="off"
            style="width:400px"
            placeholder="请输入课程分类名称"
          />
        </a-form-item>
      </a-form>
    </modal>
  </div>
</template>

<script>
import {
  PlusOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, ref, reactive, onMounted } from 'vue';
import { myPost, myGet } from "@/common/request.js"
// import ddo from '@/common/ddo.js'//数据字典对象
import { message } from "ant-design-vue"
import Modal from '@/components/modal.vue';

const api = {
  // 查询
  queryClassiTree: '/xkcwebApi/srClassification/querySrClassificationTree.htm',
  // 新增课程分类
  saveSrClassificationList: '/xkcwebApi/srClassification/saveSrClassificationList',
  // 编辑课程分类
  updateTMenuList: '/xkcwebApi/srClassification/updateTMenuList',
  // 删除课程分类
  deleteUserUrl: '/xkcwebApi/srClassification/deleteTMenuListById',
};

export default defineComponent({
  components: {
    Modal,
    PlusOutlined
  },
  setup() {
    const value = ref(null);
    let formState = reactive({
      id: null,
      pid: null,
      title: null
    });
    const customDialog = ref(null)
    const selectedKeys = ref();

    const clearFormState = (form) => {
      for (var i in form) {
        form[i] = null
      }
    }

    const modalState = ref(true);
    const operate = (type, record) => {
      modalState.value = (type === 'add' ? true : false);
      if (type === 'add') {
        formRef.value && formRef.value.resetFields()
        clearFormState(formState, 'handle');
        if (record) {
          formState.pid = record.id
        }
        customDialog.value.showModal();
      } else if (type == "edit") {
        formRef.value && formRef.value.resetFields()
        clearFormState(formState, 'handle')
        if (record) {
          formState.id = record.id,
            formState.title = record.title
        }
        customDialog.value.showModal();
      } else if (type == "delete") {
        myGet(api.deleteUserUrl, { id: record.id }).then(res => {
          message.success(res.message);
          queryTree();
        })
      }
    }


    const formStateRules = {
      title: [
        {
          required: true,
          message: "课程分类名称不能为空",
          trigger: ["blur", "change"],
          whitespace: true,
        }
      ]
    };

    const formRef = ref("")
    const sumbitEditForm = () => {
      formRef.value.validate().then(() => {
        if (modalState.value) {
          let tempParam = {
            ...formState
          }
          myPost(api.saveSrClassificationList, tempParam).then((res) => {
            if (res.code === 200) {
              queryTree()
              message.success('新增成功')
              customDialog.value.closeModal()
            }
          })
            .catch((err) => {
              console.log(err);
            })
        } else {
          let tempParam = {
            ...formState
          }
          myPost(api.updateTMenuList, tempParam).then((res) => {
            queryTree()
            message.success(res.message)
            customDialog.value.closeModal()
          })
            .catch((err) => {
              console.log(err);
            })
        }
      })
        .catch((err) => {
          console.log(err);
        })
    };

    const treeData = ref([]);
    // 列表查询
    const queryTree = () => {
      myPost(api.queryClassiTree, { id: "" }).then((res) => {
        if (res.code === 200) {
          treeData.value = res.data.srClassificationList
        }
      })
        .catch((err) => {
          console.log(err);
        })
    }
    onMounted(() => {
      queryTree(1)
      // queryAllRoles()
    })
    return {
      treeData,
      replaceFields: {
        children: 'sunTreeMenu',
        title: 'title',
        key: 'id',
        value: 'id'
      },
      labelCol: {
        span: 7,
      },
      wrapperCol: {
        span: 13,
      },
      formStateRules,
      formRef,
      formState,
      clearFormState,
      sumbitEditForm,
      selectedKeys,
      customDialog,
      operate,
      modalState,
      value
    };
  },
});
</script>
<style lang="scss" class="scoped">
.department {
  .departmentTitle {
    padding: 10px;
    background: #eee;
  }
}
.department-tree {
  width: 100%;
  font-size: 18px;
}
.department-tree-item {
  font-size: 18px;
}
.department-input {
  width: 50%;
  display: flex;
  margin: 30px auto;
}

.mb-20 {
  margin-bottom: 20px;
}
</style>

